// extracted by mini-css-extract-plugin
export var caseContactFormSection = "b2_kr";
export var caseStudyBackground__lineColor = "b2_kp";
export var caseStudyHead__imageWrapper = "b2_ns";
export var caseStudyProjectsSection = "b2_ks";
export var caseStudyQuote__bgRing = "b2_km";
export var caseStudyQuote__bottomVideo = "b2_kx";
export var caseStudyQuote__light = "b2_l8";
export var caseStudyQuote__lightLast = "b2_nt";
export var caseStudyVideo__ring = "b2_kv";
export var caseStudy__bgDark = "b2_kh";
export var caseStudy__bgLight = "b2_kg";
export var caseStudy__bgLightReverse = "b2_lJ";